<template>
  <div>
    <b-row class="mb-1">
      <b-button v-if="form_type && form_type == 'edit-event-jobs'" icon-pack="feather" icon="icon-chevron-left"
        variant="warning" class="shadow-lg ml-1" :to="{
          name:
            parentEventType == 'approved-jobs'
              ? 'participating-jobs'
              : 'pending-jobs',
          params: { event_id: parentEventID },
        }">
        Go Back
      </b-button>
      <b-button v-else-if="$route.query.action == 'add_job'" icon-pack="feather" icon="icon-chevron-left"
        variant="warning" class="shadow-lg ml-1" @click="naviTojobs">
        Participating Jobs
      </b-button>
      <!-- :to="{
          path: `/events-manager/edit-event/${this.event_id}?created=true&step_number=3`,
        }" -->
      <!-- :to="{
          name: 'participating-jobs',
          params: { event_id: parentEventID },
        }" -->
      <b-button v-else to="/events-manager/events" icon-pack="feather" icon="icon-chevron-left" variant="warning"
        class="shadow-lg ml-1">
        View All Events
      </b-button>
    </b-row>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card class="">
        <div class="container-fluid px-1 pt-1">
          <div class="">
            <div v-if="
              current_process == 'add jobs' && !event_id.endsWith('_event')
            " class="d-flex mx-6 my-4">
              <!-- !$route.params.event_id.endsWith('_event') -->
              <b-button variant="primary" icon-pack="feather" icon="icon-arrow-left" @click="naviTojobs">
                Go Back
              </b-button>
            </div>

            <div v-if="!restrict_access && !event_id && !add_event_jobs"
              class="d-flex justify-content-between mb-6 px-4 w-full">
              <!-- !this.$route.params.event_id && -->
              <b-card :border-variant="ems_section_type === 'group' ? 'primary' : 'dark'
                " bg-variant="transparent" class="shadow-none mx-1" :class="ems_section_type === 'group' ? 'bg-primary bg-lighten-7' : ''
                  ">
                <div class="d-flex flex-column">
                  <b-form-radio v-model="ems_section_type" class="font-weight-bolder" value="group"
                    @click="ems_section_type = 'group'"><span class="font-weight-bolder">Organise A Group
                      Event</span></b-form-radio>
                  <p class="mt-1 font-italic">
                    This allows you to organise a Group Event from scratch and
                    invite organisations to participate.
                  </p>
                </div>
              </b-card>

              <b-card :border-variant="ems_section_type === 'join' ? 'primary' : 'dark'
                " bg-variant="transparent" class="shadow-none mx-1" :class="ems_section_type === 'join' ? 'bg-primary bg-lighten-7' : ''
                  ">
                <div class="d-flex flex-column">
                  <b-form-radio v-model="ems_section_type" class="text-lg font-semibold" value="join"
                    @click="ems_section_type = 'join'">
                    <span class="font-weight-bolder">Participate In A Group Event</span>
                  </b-form-radio>
                  <p class="mt-1 font-italic">
                    This allows you to join upcoming Group Event that is
                    organised by another organisation.
                  </p>
                </div>
              </b-card>

              <!-- <div class="b-card mx-4">
              <div class="flex flex-col p-6">
                <b-form-radio
                  class="mx-1 text-lg font-semibold"
                  v-model="ems_section_type"
                  value="single"
                  >Single Job Event</b-form-radio
                >
                <p class="mt-1 italic">
                  A Single Event refers to an event with only a single employer
                  who's hiring. For example, your organisation's hiring event.
                </p>
              </div>
            </div> -->
            </div>
          </div>

          <b-modal v-model="questions_popup" centered hide-footer title="Edit the jobs questions below">
            <div v-if="questions_popup" class="container mx-1 mb-1">
              <p>Customised Screening Questions</p>

              <div v-if="current_interview_selection.questions">
                <div v-for="(
question, idx
                  ) in current_interview_selection.questions" :key="idx"
                  class="d-flex justify-content-end mx-auto my-1">
                  <b-form-input v-model="current_interview_selection.questions[idx]" class="mr-1" :danger="current_interview_selection.questions[idx].length > 0
                    ? false
                    : true
                    " danger-text="A valid question is required" val-icon-danger="icon-x" val-icon-pack="feather" />

                  <b-button v-b-tooltip.hover.top="'Click here to remove this question from the list.'
                    " variant="danger" @click="deleteQuestion(job_index, idx)">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </div>

              <div class="d-flex justify-between w-full mt-1">
                <b-button class="self-center mx-auto" variant="primary" type="filled" icon-pack="feather"
                  icon="icon-save" @click="saveQuestions(job_index)">Save Changes</b-button>
                <b-button class="self-center mx-auto" variant="primary" type="filled" icon-pack="feather"
                  icon="icon-edit" @click="addQuestionField(job_index)">Add Another Question</b-button>
              </div>
            </div>
          </b-modal>
          <b-modal v-model="interview_selection" centered hide-footer title="Choose Interview Type">
            <div class="container">
              <h6 class="mt-2 font-weight-bolder">Interview Type</h6>
              <div class="row">
                <div class="row w-1/2 mt-1 mx-1">
                  <b-form-radio v-model="current_interview_selection.interview_type" class="mx-2" value="physical">
                    In-Person Interview
                  </b-form-radio>
                </div>

                <div class="row w-1/2 mt-1 mx-1">
                  <b-form-radio v-model="current_interview_selection.interview_type" class="mx-2" value="virtual">
                    Virtual Interview
                  </b-form-radio>
                </div>

                <div class="row w-1/2 mt-1 mx-1">
                  <b-form-radio v-model="current_interview_selection.interview_type" class="mx-2"
                    value="speed_interview">
                    Virtual Speed Interview
                  </b-form-radio>
                </div>
              </div>

              <div v-if="
                current_interview_selection.interview_type ==
                'speed_interview'
              " class="row my-1">
                <b-form-input v-model="current_interview_selection.interview_duration" type="number" class="w-full mx-5"
                  placeholder="How many minutes should the interview last?" />
              </div>

              <div v-if="current_interview_selection.interview_type == 'physical'" class="row my-1">
                <b-form-input v-model="current_interview_selection.interview_location" type="text" class="w-full mx-5"
                  placeholder="What will be the location of the event?" />
              </div>

              <div v-if="current_interview_selection.interview_type == 'physical'" class="row my-1">
                <b-form-input v-model="current_interview_selection.max_applications" type="number" class="w-full mx-5"
                  placeholder="What will be the maximum number of participants for this event?" />

                <!-- <gmaps-map>
                        <gmaps-marker :options="interview_location" />
                      </gmaps-map> -->
              </div>

              <b-button class="mt-1 mx-auto shadow-lg w-full" variant="primary" @click="updateInterviewDetails">
                Update Interview Type
              </b-button>
            </div>
          </b-modal>

          <form-wizard ref="wizard" v-model="activeTab" :title="null" :subtitle="null" color="#f47920"
            finish-button-text="Submit" back-button-text="" class="mb-3" @on-change="stepChange">
            <!-- @on-change="stepChange" -->
            <tab-content title="Event Details" class="mb-1" :disabled="true">
              <EventForm v-if="ems_section_type === 'group'" :wizard-ref="wizard_ref" :event-id-prop="event_id"
                @on-add-event-handler="setEventIdAndName" />
              <JobForm v-else />
            </tab-content>

            <tab-content v-if="ems_section_type === 'group' || add_event_jobs" title="Participating Companies"
              class="mb-5 w-100">
              <div class="vx-row mb-2">
                <h6 class="mt-1 font-weight-bolder">Add Event Employers</h6>

                <div class="d-flex flex-wrap flex-gap-md align-items-center">
                  <CompanySearch id="register-account-company" v-model="selected_company" class="flex-grow-1"
                    :validator="true" name="register-account-company" :allow-add-new-company="true" :multiple="false"
                    :placeholder="'Start typing to get company suggestions'" :clear-select="clearSelect"
                    @company-selected="selectedCompanies" />

                  <div class="d-flex flex-wrap flex-gap-sm justify-content-start">
                    <ButtonSpinner :is-loading="isButtonLoading">
                      <b-button v-b-tooltip.hover.top="'Click here to download the excel template for adding employers.'
                        " class="" variant="warning" type="filled" @click="downloadTemplate">
                        Download Bulk Employers Template
                      </b-button>
                    </ButtonSpinner>

                    <div v-b-tooltip.hover.top="'Click to upload the excel file containing the list of employers and hiring manager emails.'
                      " class="vx-col w-full" :class="{ 'w-100': isMobile }">
                      <input ref="uploadCSV" class="hidden" type="file" accept=".xls,.xlsx"
                        @change="uploadBulkEmployers" />

                      <ButtonSpinner :is-loading="isButtonLoading">
                        <b-button id="#button-with-loading" class="w-full" variant="primary" @click="changeUpload">
                          Bulk Upload Employers
                        </b-button>
                      </ButtonSpinner>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap flex-gap-md justify-content-between my-3">
                  <b-card v-for="(details, index) in all_companies_invited" :key="index" border-variant="dark"
                    bg-variant="transparent" style="flex: 0 49%" class="shadow-none p-1 flex-fill-1">
                    <div class="d-flex justify-content-between w-100">
                      <b-form-input v-model="details.name" disabled class="col-7 w-full mx-1"
                        placeholder="Company Name" />
                      <b-form-input v-model="details.uen_no" disabled class="col w-2/5 mx-1"
                        placeholder="Company UEN NO" />

                      <b-button v-b-tooltip.hover.top="'Click here to remove this company from the invite list.'
                        " class="" variant="danger" type="filled" icon-pack="feather" icon="icon-trash"
                        @click="removeCompany(index)">
                        <feather-icon icon="TrashIcon" variant="danger" class="" />
                      </b-button>
                    </div>

                    <hr />

                    <b-row class="mt-2">
                      <h6 class="mx-auto font-weight-bolder">
                        All hiring manager emails:
                      </h6>

                      <!-- <div class="vx-row w-full mt-1">
                        <ul class="mx-auto" style="list-style-type: circle">
                          <li
                            class="font-medium"
                            :key="idx"
                            v-for="(email, idx) in all_companies_invited[index]
                              .hiring_managers"
                          >
                            {{ email }}
                          </li>
                        </ul>
                      </div> -->

                      <div class="container-fluid mx-1 overflow-auto" style="height: 16rem">
                        <div v-for="(email, idx) in all_companies_invited[index]
                          .hiring_managers" :key="idx" class="d-flex justify-content-end mx-auto">
                          <b-form-input v-model="all_companies_invited[index].hiring_managers[idx]
                            .fullname
                            " class="mx-1 mt-1" placeholder="Full Name" :danger="all_companies_invited[index].hiring_managers[idx]
                              .fullname
                              ? false
                              : true
                              " danger-text="A valid fullname is required" val-icon-danger="icon-x"
                            val-icon-pack="feather" />
                          <b-form-input v-model="all_companies_invited[index].hiring_managers[idx]
                            .email_address
                            " class="w-100 mx-1 mt-1" :class="{
                              'border-danger': !$checkEmail(
                                all_companies_invited[index].hiring_managers[
                                  idx
                                ].email_address
                              ).state,
                            }" placeholder="Email Address" :danger="all_companies_invited[index].hiring_managers[idx]
                              .email_address.length > 4
                              ? false
                              : true
                              " danger-text="A valid email address is required" val-icon-danger="icon-x"
                            val-icon-pack="feather" />

                          <b-button v-b-tooltip.hover.top="'Click here to remove this email address from the list.'
                            " class="mr-1 mt-1" variant="danger" @click="deleteEmail(index, idx)">
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </div>
                      </div>
                    </b-row>

                    <div class="d-flex justify-content-center w-100 mt-1">
                      <b-button class="self-center mx-auto" variant="primary" type="filled" icon-pack="feather"
                        icon="icon-edit" @click="addEmailField(index)">Add Another Email Field</b-button>
                    </div>
                    <!-- <b-form-input
                    v-if="details.invite_sent"
                    required
                    disabled
                    v-model="details.email"
                    :success="true"
                    success-text="Event group invite has already been sent"
                    val-icon-success="icon-check"
                    val-icon-pack="feather"
                    class="vx-col w-3/5 mt-1"
                    placeholder="Contact Email Address"
                  />

                  <b-form-input
                    v-else
                    required
                    v-model="details.email"
                    :danger="details.email_address.length > 4 ? false : true"
                    danger-text="A valid email address is required"
                    val-icon-danger="icon-x"
                    val-icon-pack="feather"
                    class="vx-col w-3/5 mt-1"
                    placeholder="Contact Email Address"
                  /> -->
                  </b-card>
                </div>
              </div>

              <div class="d-flex justify-content-center mt-1">
                <b-button variant="primary" class="shadow-lg btn-back-to-top mr-4 mt-1" @click="addInvitedCompanies">
                  Next
                </b-button>
              </div>
            </tab-content>

            <tab-content v-if="ems_section_type == 'group' || add_event_jobs" title="Add Jobs" class="mb-5 w-full">
              <div v-if="show_job_form" class="ml-1">
                <JobForm :event-id="event_id" :job-id="job_id" :allow-bulk-upload="job_id ? false : true"
                  :wizard-ref="wizard_ref" @back-group-add-job="getGroupJobSuggestions" />
              </div>
              <!-- :event-id="parentEventID" -->
              <div v-if="!show_job_form" class="d-flex justify-content-between w-full h-auto">
                <div class="">
                  <b-button variant="primary" icon-pack="feather" icon="icon-arrow-left"
                    class="shadow-lg btn-back-to-top mt-1" @click="backToEdit">
                    Back To Edit Event
                  </b-button>
                </div>

                <div class="d-flex mb-base">
                  <div class="mt-1 mb-1 mr-1">
                    <b-dropdown style="height: 40px; min-width: 180px" split :variant="action_view" :text="action_text"
                      right :icon="action_icon" type="filled" @click="performAction">
                      <b-dropdown-item @click="changeAction('Actions')">
                        <span class="d-flex justify-content-start">
                          <feather-icon icon="InfoIcon" svg-classes="" style="margin-top: 2px" class="mr-1" />
                          <span class="">Actions</span>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="changeAction('Add New Job')">
                        <span class="d-flex justify-content-left">
                          <feather-icon icon="PlusIcon" style="margin-top: 2px" class="mr-1" />
                          <span class="">Add New Job</span>
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="mt-1 mb-1 ml-1">
                    <b-button variant="warning" icon-pack="feather" icon="icon-save" class="shadow-lg"
                      @click="addPlatformJobs">Save Bulk Changes</b-button>
                  </div>
                </div>
              </div>

              <div v-if="dataRows.length > 0 && !show_job_form" class="vx-row w-full h-auto">
                <b-alert class="h-auto" :active="true" variant="warning" icon-pack="feather" icon="icon-info">
                  <span>Below are jobs that belong to the companies you have
                    invited in the previous section. Select the jobs that will
                    be added under this group event, then click `Save Changes`
                    button.
                  </span>
                </b-alert>
              </div>

              <div v-if="dataRows.length == 0 && !show_job_form" class="vx-row w-full h-auto">
                <b-alert class="p-2" show variant="warning" icon-pack="feather" icon="icon-info">
                  <span>There are no jobs that belong to the companies you have
                    invited in the previous section. You can go back to edit the
                    job posting or save your changes.
                  </span>
                </b-alert>
              </div>

              <div v-if="!show_job_form" class="">
                <vue-good-table ref="gTable" mode="remote" theme="polar-bear" :pagination-options="{
                  enabled: true,
                  perPage: serverParams.perPage,
                  perPageDropdown: [5, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                }" :search-options="{
                  enabled: false,
                }" :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }" :is-loading.sync="isLoading" :total-rows="totalRecords" :columns="columnDefs" :rows="dataRows"
                  style-class="vgt-table condensed bordered" compact-mode @on-page-change="onPageChange"
                  @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange" @on-selected-rows-change="externalSelectionChanged">
                  <template slot="column-filter" slot-scope="props">
                    <span v-if="props.column.field === 'created_on'" class="mt-2">
                      <!-- <flat-pickr
                        v-model="created_on_filter"
                        :config="configPicker"
                        class="vgt-input"
                        placeholder="Filter Added On"
                        @input="(value) => updateFilters(props.column.field, value)"
                      /> -->

                      <b-button variant="warning" @click="showDateRangeFilter(true, props.column.field)">
                        Click to filter the date
                      </b-button>
                      <b-button v-if="props.column.field in serverParams.columnFilters" class="ml-2"
                        @click="resetDateRangeFilter(true, props.column.field)">Reset</b-button>
                    </span>
                  </template>

                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Status -->
                    <span v-if="props.column.field === 'interview_type'">
                      <p class="font-medium truncate">
                        {{ humanizedInterviewType(props.row.interview_type) }}
                      </p>
                      <b-button class="shadow-lg w-full" variant="primary"
                        @click="updateInterviewType(props.row, props.index)">Update Type
                      </b-button>
                    </span>
                    <span v-else-if="props.column.field === 'questions'">
                      <p class="font-medium truncate">
                        {{ props.row.questions.length }} Question(s)
                      </p>
                      <b-button class="shadow-lg w-full font-medium truncate" variant="primary"
                        @click="updateQuestions(props.row, props.index)">Edit Questions
                      </b-button>
                    </span>

                    <span v-else-if="props.column.field === 'created_on'">
                      <p class="m-0 p-0 font-medium truncate">
                        {{
                          props.row.created_on
                            ? props.row.created_on
                            : "Not yet posted"
                        }}
                      </p>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                </vue-good-table>
              </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left" />
              <div class="wizard-footer-right" />
            </template>
          </form-wizard>
        </div>
      </b-card>
    </b-overlay>

    <b-modal v-model="dateRangeFilter" centered hide-footer title="Choose the desired date range to filter the data"
      class="bg-danger" size="lg">
      <v-date-picker v-model="startEndDate" mode="date" :masks="masks" color="orange" :columns="2" is-range
        is-expanded />
    </b-modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { format, add, sub, parse } from "date-fns"
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard"
import vSelect from "vue-select"
import "vue-form-wizard/dist/vue-form-wizard.min.css"

import { debounce, reject, orderBy } from "lodash"

import { BCard, VBTooltip, BPagination, BFormSelect } from "bootstrap-vue"
import EventForm from "@/components/jobs/EventForm.vue"
import JobForm from "@/components/jobs/JobForm.vue"
import { VueGoodTable } from "vue-good-table"
import { allIndustries, allCountries } from "@/constants/datastore"
import { onResizeMixins } from "@/components/mixins/onResizeMixins"
import CompanySearch from "@/components/CompanySearch.vue"

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BCard,
    EventForm,
    JobForm,
    vSelect,
    flatPickr,
    FormWizard,
    TabContent,
    CompanySearch,
    WizardStep,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      parentEventType: "approved-jobs",
      parentEventID: null,
      clearSelect: false,
      allCountries: [],
      activeTab: 0,
      redirection_link: "",
      featured: false,
      questions_popup: false,
      selected_events: [],
      isMounted: false,
      add_event_jobs: false,
      dataRows: [],
      totalRecords: 0,
      share_link: "",
      social_popup: false,
      event_id: "",
      job_id: null,
      resume_id: "",
      interview_time: "",
      event_visibility: "public",
      bulk_upload: false,
      bulk_upload_group: false,
      interview_popup: false,
      popup_image: "",
      document_type: "pdf",
      // https://api.headhuntershq.com/api/media/601853647.docx
      file_source: "",
      restrict_access: false,
      selected_company: "",
      all_companies_invited: [],
      interview_duration: 0,
      interview_location: "",
      max_applications: "",
      custom_questions: "",
      application_statuses: [
        { text: "Hired", value: "Hired" },
        { text: "Shortlisted", value: "Shortlisted" },
        { text: "Kept In View", value: "Kept In View" },
        { text: "Rejected", value: "Rejected" },
      ],
      selected_application_status: "",
      application_status_popup: false,
      show_desc: {
        one: true,
        two: false,
        three: false,
      },

      description_manager: {},
      specified_event: {},
      inside_preview_popup: false,
      preview_popup: false,
      attendance_popup: false,
      selected_applicants: [],
      itemsPerPage: {
        jobs: 6,
        applicants: 6,
        events: 6,
      },
      view_applicants: false,
      applicants: [],
      archive_live_type: true,
      event_section_type: true,
      ems_section_type: "group",
      job_type: "",
      employment_type: {
        freelance: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
      },
      all_available_slots: [],
      filtered_slots: [],
      event_employers: [],
      selected_event_employers: [],
      position: "",
      description: "",
      job_source: "",
      requirements: "",
      type_candidate: "",
      work_days: "",
      work_hours: "",
      salary: 0,
      min_salary: 0,
      salary_cycle: "",
      event_status: "draft",
      work_location: {
        country: "Singapore",
        postal_code: "",
        state: "",
        address: "",
      },
      remote: false,
      event_type: "",
      applications: [],
      job: {},
      file_upload: "",
      popupActive: false,
      single_preview_popup: false,
      group_preview_popup: false,
      group_preview_link: "",
      popupList: false,
      popupApplicant: false,
      image_url: "default",
      event_exist: false,
      action: "insert",
      registered: [],
      current_item: {},
      item_id: "",
      events_data: [],
      select_tags: [],
      selected_event_group: "",
      selected_hiring_managers: "",
      all_companies_hiring_managers: {},
      hiring_managers: [],
      event_groups: [],
      start_end_date: "",
      masks: {
        input: "YYYY-MM-DD",
      },
      start_date: "",
      end_date: "",
      current_user: "",
      start_time: "",
      end_time: "",
      applicant_info: {},
      all_event_records: {},
      recorded_ids: [],
      all_salary_cycles: [
        //
        { text: "Yearly", value: "Yearly" },
        { text: "Monthly", value: "Monthly" },
        { text: "Weekly", value: "Weekly" },
        { text: "Daily", value: "Daily" },
        { text: "Hourly", value: "Hourly" },
      ],
      categories: [],
      dates: [],
      inside_data: {},
      inside_event_type: "",
      inside_event_category: "",
      application_note: "",
      booking_id: "",
      current_interview_type: "",

      current_table_index: 0,
      current_applicant_event: {},
      pickerLimits: {},
      booked_slots: [],
      forbidden_emails: [],
      ems_file_popup: false,
      group_template: "group_ems.xlsx",

      selected_participating_events: "",
      participating_events_data: [],
      specific_event_details: {},
      all_selected_companies: [],

      searchTimer: null,

      job_index: 0,
      aging_period: 0,
      notify_employer: false,
      notify_text: "",

      calendarAttributes: [
        {
          key: "today",
          dot: true,
          highlight: "red",
          dates: new Date(),
        },
      ],

      all_hiring_emails: [""],
      show_special_back: false,
      current_process: "",
      jobs_company: "",
      hiring_manager_email: "",
      interview_selection: false,
      current_interview_selection: {},

      action_view: "dark",
      action_text: "Actions",
      action_icon: "icon-info",

      pageLength: 5,
      dir: false,
      columnDefs: [
        {
          label: "Job ID",
          field: "id",
          width: "300px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Job Position",
          field: "position",
          width: "300px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Job Type",
          field: "job_type",
          width: "150px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Interview Type",
          field: "interview_type",
          width: "180px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
            filterDropdownItems: [
              { value: "physical", text: "In-Person Interview" },
              { value: "virtual", text: "Virtual Interview" },
              { value: "speed_interview", text: "Virtual Speed Interview" },
            ],
          },
        },
        {
          label: "Job Questions",
          field: "questions",
          width: "200px",
          sortable: false,
          filterOptions: {
            trigger: "enter",
            enabled: false,
            placeholder: "",
          },
        },
        {
          label: "Job Salary",
          field: "salary",
          width: "150px",
          filterOptions: {
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Job Location",
          field: "location",
          width: "300px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Company Name",
          field: "company_name",
          width: "300px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Poster's FullName",
          field: "poster_fullname",
          width: "170px",
          filterOptions: {
            trigger: "enter",
            enabled: true,
            placeholder: "",
          },
        },
        {
          label: "Posted On",
          field: "created_on",
          width: "320px",
          type: "date",
          dateInputFormat: "do MMM yyyy HH:mm", // expects 2018-03-16
          dateOutputFormat: "do MMM yyyy HH:mm", // outputs Mar 16th 2018
          tdClass: "text-left",
          thClass: "text-left",
          filterOptions: {
            customFilter: true,
            trigger: "enter",
            enabled: true,
            placeholder: "",
            // filterValue: '01-03-2023',
          },
          cellRendererFramework: "CellRendererEventOn",
        },
      ],
      rows: [],
      searchTerm: "",
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      created_on_filter: null,

      isLoading: false,
      isButtonLoading: false,

      // nult upload
      show_bulk: false,

      // temporary variable because moving fields from ATS to EMS Job
      benefit: "",

      published_fields: {},
      optional: {},

      all_salary_currencies: [
        { text: "SGD", value: "SGD" },
        { text: "USD", value: "USD" },
        { text: "EUR", value: "EUR" },
        { text: "GBP", value: "GBP" },
      ],
      form_type: null,
      show_job_form: false,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      wizard_ref: null,

      startEndDate: null,

      dateRangeFilter: false,
      dateRangeField: null,
      prevRoute: null,

      // * Part of removing loading page bug fix
      eventName: "",
    }
  },

  computed: {},

  watch: {
    "$route.path": function (val, oldVal) {
      this.initComponent()
    },

    start_end_date(val) {
      this.start_date = format(val.start, "yyyy-MM-dd")
      this.end_date = format(val.end, "yyyy-MM-dd")
    },

    all_hiring_emails(val) { },

    ems_section_type(val) {
      this.pickerLimits = {
        enableTime: false,
        minDate: sub(new Date(), { months: 3 }),
        maxDate: add(new Date(), { years: 1 }),
      }
      this.bulk_upload = false
      this.bulk_upload_group = false

      try {
        if (val === "join" || val === "single") {
          this.toggleStepWizardDisplay("hide")
          this.$refs.event_tabs.children = reject(
            this.$refs.event_tabs.children,
            (el) => el.label === "Add Jobs"
          )
        } else {
          this.toggleStepWizardDisplay("show")
        }
      } catch (error) { }

      if (val === "group") {
        this.$addParamsToUrl(this, "action", "add_event")
      } else if (val === "join") {
        this.$addParamsToUrl(this, "action", "add_job")
      }
    },
    event_section_type(val) {
      this.getEvents()
    },
    archive_live_type(val) {
      this.getEvents()
    },
    selected_application_status(val) { },
    selected_event_group(val) {
      const specific_event = this.events_data.filter((x) => x.id === val)[0]
      if (specific_event) {
        const employer = this.$store.state.auth.ActiveUser.official_company_name

        const hiring_managers = []
        const firstItem = Object.keys(this.all_companies_hiring_managers)[0]

        this.all_companies_hiring_managers[firstItem].map((user) => {
          hiring_managers.push({ text: user, value: user })
        })

        this.hiring_managers = hiring_managers
      }
      const selected_group = this.event_groups.filter(
        (item) => item.value === val
      )[0]

      this.notify_employer = false
      if (selected_group) {
        this.pickerLimits = {
          enableTime: false,
          minDate: new Date(selected_group.extra_info.start_date),
          maxDate: new Date(selected_group.extra_info.end_date),
        }

        if (selected_group.extra_info.aging_period !== 0) {
          this.notify_employer = true
          this.notify_text =
            "The organiser has allowed you to view the applications of this job for " +
            `${selected_group.extra_info.aging_period} days after the event ends.`
        }
      }
    },
    all_companies_invited(val) { },
    selected_company(val) {
      if (Object.keys(val).length > 0) {
        const isPresent = this.all_companies_invited.some((item) =>
          item.company_id.includes(val.id)
        )

        if (!isPresent) {
          this.all_companies_invited.push({
            name: val.company_name,
            uen_no: val.company_uen_no,
            company_id: val.id,
            hiring_managers: [],
          })
          this.clearSelect = true
        }
      }
    },

    startEndDate(val) {
      const column = this.dateRangeField

      const startDate = format(val.start, "yyyy-MM-dd")
      const endDate = format(val.end, "yyyy-MM-dd")

      const value = { from: startDate, to: endDate }
      this.updateFilters(column, value)
      this.dateRangeFilter = false
    },
  },
  mounted() {
    this.wizard_ref = this.$refs.wizard
    this.parentEventID = localStorage.getItem("event-id")

    if (this.parentEventID) {
      if (this.parentEventID.includes("_job")) {
        this.parentEventType = "approved-jobs"
        this.parentEventID = this.parentEventID.replace("_job", "")
      } else if (this.parentEventID.includes("_pending")) {
        this.parentEventID = this.parentEventID.replace("_pending", "")
        this.parentEventType = "pending-jobs"
      } else if (this.parentEventID.includes("_event")) {
        this.parentEventID = this.parentEventID.replace("_event", "")
      }
    }


    this.initComponent()

    // if (this.$route.params.event_id && this.$route.query.created === "true") {
    //   // this.$refs.wizard.nextTab()
    //   this.event_id = this.$route.params.event_id
    //   this.toggleStepWizardDisplay("show")
    // }

    // this.$refs.wizard.nextTab()

    if (
      this.event_id &&
      (this.event_id.endsWith("_job") ||
        this.event_id.endsWith("_pending") ||
        this.event_id.endsWith("_event"))
    ) {
      this.toggleStepWizardDisplay("hide")
    }


    if (this.$isNotEmpty(this.$route.query.step_number)) {
      switch (parseInt(this.$route.query.step_number, 0)) {
        case 1:
          this.$refs.wizard.changeTab(0, 0)
          break

        case 2:
          this.$refs.wizard.changeTab(0, 1)
          break

        case 3:
          this.$refs.wizard.changeTab(0, 2)
          this.show_job_form = false
          break
        case 3 &&
          this.event_id &&
          !this.event_id.endsWith("_job") &&
          !this.event_id.endsWith("_pending") &&
          !this.event_id.endsWith("_event"):
          this.getServerJobs()
          this.$refs.wizard.changeTab(0, 2)
          break

        case 3:
          if (
            !this.event_id.endsWith("_job") &&
            !this.event_id.endsWith("_event")
          ) {
            this.getServerJobs()
          }

          this.$refs.wizard.changeTab(0, 2)

          setTimeout(() => {
            if (this.$route.query.action === "add_job") {
              this.addGroupJobs()
            }
          }, 5)
          break

        default:
          break
      }
    }

  },
  created() {
    const { email_address } = this.$store.state.auth.ActiveUser
    if (email_address === "recruiter@headhuntershq.com") {
      this.restrict_access = false
    }
    this.categories = orderBy(allIndustries, ["value"], ["asc"])
    this.allCountries = orderBy(allCountries, ["value"], ["asc"])

    if (this.$route.params.event_id) {
      // this.$refs.wizard.nextTab()
      if (this.event_id.endsWith("_job")) {
        this.event_id = this.$route.params.event_id.split("_job")[0]
      } else if (!this.event_id.endsWith("_pending")) {
        this.event_id = this.$route.params.event_id.split("_pending")[0]
      } else if (!this.event_id.endsWith("_event")) {
        this.event_id = this.$route.params.event_id.split("_event")[0]
      } else {
        this.event_id = this.$route.params.event_id
      }

      this.toggleStepWizardDisplay("show")
    }

    if (this.$route.query.start) {
      this.start_date = this.$route.query.start
      this.end_date = this.$route.query.end
      this.start_end_date = {
        start: parse(this.start_date, "yyyy-MM-dd", new Date()),
        end: parse(this.end_date, "yyyy-MM-dd", new Date()),
      }
    }
  },

  methods: {
    // * part of fixing loading screen bug
    setEventIdAndName(eventId) {

      this.event_id = eventId

      this.$refs.wizard.changeTab(0, 1)

      // this.$refs.wizard.nextTab()

      // history.replaceState(
      //   {},
      //   "",
      //   `${this.$route.path}/events-manager/edit-event/${eventId}?created=true&step_number=2`
      // )

      this.toggleStepWizardDisplay("show")
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getServerJobs()
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = {}
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },

    onColumnFilter(params) {
      this.updateParams(params)
    },
    updateFilters(field, value) {
      this.serverParams.columnFilters[field] = value
      this.updateParams(this.serverParams.columnFilters)
    },
    initComponent() {
      this.isMounted = true
      // this.event_id = this.$route.params.event_id

      if (this.event_id && this.event_id.endsWith("-N")) {
        // this.event_id = this.event_id.replace('-N', '')
        //! Sugianto: in case if event_id uuid contains -N
        this.event_id = this.event_id.trim().slice(0, -2)
      } else if (this.event_id && this.event_id.endsWith("_event")) {
        // this.event_id = this.event_id.replace('-N', '')
        //! Sugianto: in case if event_id uuid contains -N
        this.event_id = this.event_id.trim().slice(0, -6)
      } else if (this.event_id && this.event_id.endsWith("_pending")) {
        // job when edit clicked from Number of pending jobs on Event
        this.$set(this, "job_id", this.event_id.trim().slice(0, -8))
        if (this.job_id) {
          this.$refs.wizard.changeTab(0, 2)
          this.form_type = "edit-event-jobs"
          this.show_job_form = true
        }
      } else if (this.event_id && this.event_id.endsWith("_job")) {
        // job when edit clicked from participating-jobs
        if (this.$route.query.action !== "add_job") {
          this.$set(this, "job_id", this.event_id.trim().slice(0, -4))
        }
        if (this.job_id) {
          this.$refs.wizard.changeTab(0, 2)
          this.form_type = "edit-event-jobs"
          this.show_job_form = true
        }
      }
    },
    stepChange(prevIndex, currentIndex) {
      // this.$replaceUrl(this, this.$route.name, this.$route.params, {
      //   ...this.$route.query,
      //   step_number: currentIndex + 1,
      // })

      if (currentIndex === 0) {
        // history.replaceState(
        //   {},
        //   "",
        //   `${this.$route.path}/events-manager/edit-event/${this.event_id}?created=true&step_number=1`
        // )

        this.$router
          .push(
            `/events-manager/edit-event/${this.event_id}?created=true&step_number=1`
          )
          .catch((error) => {
            console.log(`navigation error: ${error}`)
          })
      } else if (currentIndex === 1) {
        // history.replaceState(
        //   {},
        //   "",
        //   `${this.$route.path}/events-manager/edit-event/${this.event_id}?created=true&step_number=2`
        // )

        this.$router
          .push(
            `/events-manager/edit-event/${this.event_id}?created=true&step_number=2`
          )
          .catch((error) => {
            console.log(`navigation error: ${error}`)
          })

        this.getInvitedCompanies()
      } else if (currentIndex === 2) {
        this.$router.push({
          path: `/events-manager/edit-event/${this.$route.params.event_id}`,
          query: { ...this.$route.query, created: true, step_number: 3 }
        }).catch((error) => {
          console.log(`navigation error: ${error}`)
        });

        // history.replaceState(
        //   {},
        //   "",
        //   `${this.$route.path}/events-manager/edit-event/${this.event_id}?created=true&step_number=3`
        // )
      }
    },
    toggleStepWizardDisplay(type = "show") {
      try {
        document.querySelector(".wizard-progress-with-circle").style.display =
          type === "hide" ? "none" : null
        document.querySelector(".wizard-nav-pills").style.display =
          type === "hide" ? "none" : null
        document.querySelector(".wizard-header").style.display =
          type === "hide" ? "none" : null
        document.querySelector(".wizard-header").classList.add("p-0")
      } catch (error) {

      }

    },
    getGroupJobSuggestions(val) {
      this.show_job_form = false
      this.getServerJobs()
      // this.add_event_jobs = false
    },
    getServerJobs() {
      this.isLoading = true
      this.restoreCreds()
      const baseUrl = `/api/suggested-event-jobs/${this.event_id}`
      localStorage.setItem("event-id", this.event_id)

      let query = ""
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`${baseUrl}${query}`)
        .then((response) => {
          this.dataRows = response.data.jobs
          this.totalRecords = response.data.totalRecords
          this.isLoading = false
        })
        .catch((error) => {
          // this.$handleErrorResponse(error)
          this.isLoading = false
        })
    },
    addInvitedCompanies() {
      let proceed = true
      this.all_companies_invited.forEach((invited) => {
        invited.hiring_managers.forEach((manager) => {
          if (!this.$checkEmail(manager.email_address).state) {
            proceed = false
          }
        })
      })

      const formData = this.all_companies_invited.map((company) => ({
        company_id: company.company_id,
        hiring_managers: company.hiring_managers,
        event_id: this.event_id,
      }))
      const data = { all_invites: formData }

      if (!proceed) {
        this.$toastDanger("An Error Occured", "Invalid values")
        return
      }
      this.isLoading = true
      this.$http
        .post("/api/invited-companies", data)
        .then((response) => {
          const { success } = response.data
          this.isLoading = false
          if (success) {
            this.$refs.wizard.nextTab()

            this.getServerJobs()
            // this.$refs.gTable.filteredRows[0].children.map((item, index) => {
            //   if (participatingIds.includes(item._id)) {
            //     this.$set(this.dataRows[index], 'vgtSelected', true)
            //   }
            // })

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Event Manager",
                  icon: "AlertCircleIcon",
                  text: "The list of employers for this event has been updated successfully",
                  variant: "success",
                },
              },
              { timeout: this.$longestTimeout }
            )
          }
        })
        .catch((error) => {
          this.isLoading = false

          if (error.response.status === 422) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Event Manager",
                  icon: "AlertCircleIcon",
                  text: "At least one hiring manager is required for every invited company.",
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            )
          }
        })
    },
    getInvitedCompanies() {
      // * part of fixing loading screen bug
      this.isLoading = true
      // let eventID = this.$route.params.event_id
      let eventID = this.event_id
      if (eventID) {
        eventID = eventID.replace("-N", "")
        this.$http
          .get(`/api/invited-companies/${eventID}`)
          .then((response) => {
            this.all_companies_invited = response.data.data
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }

      this.isLoading = false
    },
    selectedCompanies(company) {
      if (
        this.$isNotEmpty(company.company_uen_no) &&
        this.$isNotEmpty(company.id)
      ) {
        this.selected_company = company
        this.selected_company.company_id = company.id
      }
      // this.formData.company_id = company.id
    },
    externalSelectionChanged(params) {
      this.participating_events_data = params.selectedRows

      const selectedIdArray = params.selectedRows.map((item) => item.id)

      this.dataRows.map((item, loopIndex) => {
        const updatedItem = item
        if (selectedIdArray.includes(item.id)) {
          updatedItem.vgtSelected = true
        } else {
          updatedItem.vgtSelected = false
        }
        return updatedItem
      })
    },
    cleanRedirectionLink() {
      if (this.redirection_link) {
        this.redirection_link = this.redirection_link
          .toLowerCase()
          .replace("https://", "")
          .replace("https://", "")
        if (this.redirection_link.length > 0) {
          this.redirection_link = `https://${this.redirection_link}`
        } else {
          this.redirection_link = ""
        }
      } else {
        this.redirection_link = ""
      }

      return this.redirection_link
    },
    timeError(selected_time) {
      try {
        const formatted_time = format(selected_time, "HH:mm:ss")
        return formatted_time
      } catch (error) {
        return ""
      }
    },
    performAction() {
      if (this.action_text === "Add New Job") {
        this.addGroupJobs()
      } else if (this.action_text === "Delete Selected Jobs") {
        this.deleteJobs()
      }
    },
    changeAction(text) {
      this.action_text = text
      switch (text) {
        case "Add New Job":
          this.action_view = "success"
          this.action_icon = "icon-plus"
          this.addGroupJobs()
          break
        case "Delete Selected Jobs":
          this.action_view = "danger"
          this.action_icon = "icon-trash"
          this.deleteJobs()
          break
        case "Jobs Pending Approval":
          this.action_view = "warning"
          this.action_icon = "icon-key"
          break
        case "Actions":
          this.action_view = "dark"
          this.action_icon = "icon-info"
          break
        default:
          this.action_view = "dark"
          this.action_icon = "icon-info"
      }
    },
    deleteJobs() {
      if (this.participating_events_data.length > 0) {
        for (const selected_job of this.participating_events_data) {
          this.dataRows = this.dataRows.filter(
            (item) => item._id !== selected_job._id
          )
          this.$store
            .dispatch("auth/createPlatformJobs", this.dataRows)
            .catch((err) => { })
        }

        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: "Event Jobs",
              icon: "AlertCircleIcon",
              text: "The selected jobs have been removed from the list.",
              variant: "success",
            },
          },
          { timeout: this.$longestTimeout }
        )
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: "Event Jobs",
              icon: "AlertCircleIcon",
              text: "You need to select the jobs that you want to remove from the list.",
              variant: "danger",
            },
          },
          { timeout: this.$longestTimeout }
        )
      }
    },
    updateQuestions(tr, indextr) {
      this.questions_popup = true
      this.job_index = indextr

      const questions = [...tr.questions]

      this.current_interview_selection = {
        questions,
      }
    },
    getCurrentTab() {
      return this.$refs.wizard.activeTabIndex
    },

    async downloadTemplate() {
      const template = "bulk_employers.xlsx"

      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "Downloading File",
            icon: "AlertCircleIcon",
            text: "Downloading template in progress.",
            variant: "warning",
            progress: 0,
            progressId: "downloadTemplate",
          },
        },
        { timeout: 9999999999 }
      )

      const templateUrl = await this.$generateDownloadUrl(
        `templates/${template}`
      )

      this.isButtonLoading = true
      fetch(templateUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement("a")

          fileLink.href = fileURL
          fileLink.setAttribute("download", "bulk_employers_template.xlsx")
          document.body.appendChild(fileLink)

          fileLink.click()
          this.isButtonLoading = false
        })
        .catch(() => {
          this.isButtonLoading = false
        })

      // axios.get(templateUrl, {
      //   responseType: 'arraybuffer',
      //   onDownloadProgress(progressEvent) {
      //     const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      //     const parent = document.querySelector('#downloadTemplate')
      //     parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
      //   },
      // })
      //   .then(response => {
      //     const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      //     const fileLink = document.createElement('a')

      //     fileLink.href = fileURL
      //     fileLink.setAttribute('download', 'bulk_employers_template.xlsx')
      //     document.body.appendChild(fileLink)

      //     fileLink.click()
      //   }).catch(error => {
      //     console.log(error)
      //   })
    },

    updateInterviewType(record, index) {
      this.current_interview_selection = {
        interview_type: record.interview_type,
        interview_duration: record.interview_duration,
        interview_location: record.interview_location,
        max_applications: record.max_applications,
        index,
      }

      this.interview_selection = true
    },

    updateInterviewDetails() {
      const list_index = this.current_interview_selection.index

      const payload = {
        index: list_index,
        updates: {
          interview_type: this.current_interview_selection.interview_type,
          interview_duration:
            this.current_interview_selection.interview_duration,
          interview_location:
            this.current_interview_selection.interview_location,
          max_applications: this.current_interview_selection.max_applications,
        },
      }

      this.$store
        .dispatch("auth/updatePlatformJobsItem", payload)
        .catch((err) => { })
      const updateRows = JSON.parse(
        JSON.stringify(this.$store.state.auth.platformJobs)
      )

      const updatedRow = {
        ...this.dataRows[list_index],
        ...updateRows[list_index],
      }
      this.$set(this.dataRows, list_index, updatedRow)
      this.$set(this.dataRows[list_index], "vgtSelected", false)
      this.$set(this.dataRows[list_index], "vgtSelected", true)

      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "Interview Type",
            icon: "AlertCircleIcon",
            text: "You have changed the interview type successfully.",
            variant: "success",
          },
        },
        { timeout: this.$longestTimeout }
      )
      this.interview_selection = false
    },
    showSalaryWarning() {
      let result = false
      if (this.salary === 0) {
        result = true
      }

      if (parseInt(this.min_salary) > parseInt(this.salary)) {
        result = true
      }
      return result
    },
    addEventJob() {
      const all_data = this.extract_values()
      all_data._id = "-"
      all_data.location = all_data.work_location
      all_data.type = all_data.job_type
      all_data.questions = [all_data.questions]
      all_data.interview_type =
        this.$store.state.auth.specificEvent.interview_type
      all_data.interview_duration =
        this.$store.state.auth.specificEvent.interview_duration
      all_data.interview_location =
        this.$store.state.auth.specificEvent.interview_location

      const name = this.jobs_company.split("#")[0]
      const uen_no = this.jobs_company.split("#")[1]
      all_data.employer = name
      all_data.fullname = this.$store.state.auth.ActiveUser.fullname

      all_data.date = format(new Date(), "yyyy-MM-dd")

      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "Event Manager",
            icon: "AlertCircleIcon",
            text: "Your job has been added successfully.",
            variant: "success",
          },
        },
        { timeout: this.$longestTimeout }
      )

      this.ems_section_type = "group"
      this.selected_event_group = this.$store.state.auth.specificEvent._id || ""
      const data_id =
        this.selected_event_group.length > 0
          ? this.selected_event_group
          : this.item_id

      this.$store
        .dispatch("auth/updatePlatformJobs", [all_data])
        .catch((err) => { })
      this.dataRows = JSON.parse(
        JSON.stringify(this.$store.state.auth.platformJobs)
      )
      // this.$refs.wizard.changeTab(0, 1);
      this.$router.push("/applicant-tracking")
      this.current_process = ""
      this.add_event_jobs = false
    },
    naviTojobs() {
      // this.$refs.wizard.changeTab(0, 1);
      this.ems_section_type = "group"
      this.selected_event_group = this.$store.state.auth.specificEvent._id || ""
      this.specific_event_details = { ...this.$store.state.auth.specificEvent }
      this.add_event_jobs = false
      this.show_job_form = false
      this.current_process = ""
      this.$refs.wizard.changeTab(0, 2)
      this.editEvent()
    },
    addGroupJobs() {
      this.show_job_form = true
      // this.$refs.wizard.changeTab(1, 0);
      // this.$refs.wizard.prevTab();
      const data_id = this.selected_event_group
        ? this.selected_event_group
        : this.item_id
      this.selected_event_employers = []

      for (const company of this.all_companies_invited) {
        this.selected_event_employers.push({
          text: company.name,
          value: `${company.name}#${company.uen_no}`,
        })
      }

      this.add_event_jobs = true
      this.current_process = "add jobs"
      this.ems_section_type = "join"
      this.selected_event_group = data_id
    },
    backToEdit() {
      // this.$refs.wizard.changeTab(1, 0);
      this.$refs.wizard.prevTab()
    },
    deleteEmail(index, idx) {
      const details = this.all_companies_invited[index]
      const { fullname } =
        this.all_companies_invited[index].hiring_managers[idx]
      const emailAddress =
        this.all_companies_invited[index].hiring_managers[idx].email_address

      if (this.$isEmpty(fullname) && this.$isEmpty(emailAddress)) {
        this.all_companies_invited[index].hiring_managers.splice(idx, 1)
      }

      this.isLoading = true
      this.$http
        .delete(
          `/api/invited-companies-email/${details.event_id}/${emailAddress}`
        )
        .then((response) => {
          const { success } = response.data

          this.isLoading = false
          if (success) {
            this.all_companies_invited[index].hiring_managers.splice(idx, 1)
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Events Notification",
                  icon: "AlertCircleIcon",
                  text: "Email address removed successfully",
                  variant: "success",
                },
              },
              { timeout: this.$longestTimeout }
            )
          }
        })
        .catch((error) => { })
    },

    deleteQuestion(index, idx) {
      this.current_interview_selection.questions.splice(idx, 1)
      // this.dataRows[index].questions.splice(idx, 1);
    },
    addEmailField(index) {
      this.all_companies_invited[index].hiring_managers.push({
        fullname: "",
        email_address: "",
      })
    },
    addQuestionField(index) {
      this.current_interview_selection.questions.push("")
    },
    saveQuestions(index) {
      const list_index = index

      this.questions_popup = false
      const payload = {
        index,
        updates: {
          questions: this.current_interview_selection.questions,
        },
      }

      this.$store
        .dispatch("auth/updatePlatformJobsItem", payload)
        .catch((err) => { })
      const updateRows = JSON.parse(
        JSON.stringify(this.$store.state.auth.platformJobs)
      )

      const updatedRow = {
        ...this.dataRows[list_index],
        ...updateRows[list_index],
      }
      this.$set(this.dataRows, list_index, updatedRow)
      this.$set(this.dataRows[list_index], "vgtSelected", false)
      this.$set(this.dataRows[list_index], "vgtSelected", true)
    },
    afterHiringEmails() {
      const all_data = {}
      this.restoreCreds()

      const data_id = this.selected_event_group
        ? this.selected_event_group
        : this.item_id

      if (Object.keys(this.$store.state.auth.specificEvent).length > 0) {
        this.specific_event_details = this.$store.state.auth.specificEvent
      }

      this.$http
        .post("/api/invited-companies", { all_invites })
        .then((response) => {
          this.$store
            .dispatch(
              "auth/createPlatformJobs",
              response.data.all_selected_events
            )
            .catch((err) => { })
          this.dataRows = response.data.all_selected_events

          this.all_selected_events = response.data.all_selected_events

          this.participating_events_data = response.data.platform_jobs

          const participatingIds = this.participating_events_data.map(
            (item) => item._id
          )

          if (this.all_selected_companies.length > 0) {
            this.participating_events_data = this.dataRows.filter((job) =>
              this.all_selected_events.includes(job._id)
            )
          }

          this.$refs.wizard.nextTab()
          this.$refs.gTable.filteredRows[0].children.map((item, index) => {
            if (participatingIds.includes(item._id)) {
              this.$set(this.dataRows[index], "vgtSelected", true)
            }
          })

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: "Event Manager",
                icon: "AlertCircleIcon",
                text: "The list of employers for this event has been updated successfully",
                variant: "success",
              },
            },
            { timeout: this.$longestTimeout }
          )
        })
        .catch((error) => { })
    },
    getFormButton() {
      const ems_section_type = ""
      if (action === "insert") {
        ems_section_type = "group" ? "Add Event Group" : "Add Event"
        return ems_section_type
      }

      ems_section_type = "group" ? "Edit Event Group" : "Edit Event"

      return ems_section_type
    },
    changeUpload() {
      this.$refs.uploadCSV.value = ""
      this.$refs.uploadCSV.click()
    },
    removeCompany(index) {
      const details = this.all_companies_invited[index]
      this.isLoading = true
      this.$http
        .delete(
          `/api/invited-companies/${details.event_id}/${details.company_id}`
        )
        .then((response) => {
          const { success } = response.data

          this.isLoading = false
          if (success) {
            this.all_companies_invited.splice(index, 1)
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Events Notification",
                  icon: "AlertCircleIcon",
                  text: "Company has been removed successfully",
                  variant: "success",
                },
              },
              { timeout: this.$longestTimeout }
            )
          }
        })
        .catch((error) => {
          if (this.$isEmpty(details.hiring_managers)) {
            this.all_companies_invited.splice(index, 1)
          }
          this.isLoading = false
        })
    },
    addPlatformJobs() {
      this.isLoading = false

      if (this.dataRows.length > 0) {
        const join_event_id = this.$route.params.event_id

        const data_id = this.selected_event_group
          ? this.selected_event_group
          : this.item_id
        let selectedJobsID = this.participating_events_data
        if (this.participating_events_data.length === 0) {
          selectedJobsID = this.dataRows.map((item) => item.id)
        }

        const all_data = {
          participating_events: selectedJobsID,
          selected_event_group: data_id,
          action: this.action,
        }

        this.$http
          .post("/api/bulk-events", { data: all_data })
          .then((response) => {
            const { success } = response.data
            const { message } = response.data
            this.isLoading = false
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Events Notification",
                    icon: "AlertCircleIcon",
                    text: message,
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Events Notification",
                    icon: "AlertCircleIcon",
                    text: "Event updated successfully",
                    variant: "warning",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }
          })
          .catch((error) => { })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: "Events Notification",
              icon: "AlertCircleIcon",
              text: "No events were selected. You can edit the job later if you want to add jobs in the future.",
              variant: "success",
            },
          },
          { timeout: this.$longestTimeout }
        )
      }
      this.isLoading = false
      this.$router.push("/events-manager/events")
    },

    uploadBulkEmployers(input) {
      this.file_upload = input

      this.$toastWarning(
        "File Attachment",
        "File has been attached successfully and is being uploaded."
      )

      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => { }
        const fd = new FormData()
        const eventID = this.$route.params.event_id

        fd.append("file", input.target.files[0])

        // delete this.$http.defaults.headers.common.Authorization
        this.isButtonLoading = true
        this.$http
          .post(`/api/upload-bulk-employers/${eventID}`, fd)
          .then((response) => {
            this.isButtonLoading = false
            if (response.data.errors.length > 0) {
              this.$bvModal
                .msgBoxConfirm(response.data.errors.join("\n"), {
                  title: "Errors detected",
                  size: "sm",
                  okVariant: "danger",
                  okTitle: "Upload New File",
                  cancelTitle: "Cancel",
                  cancelVariant: "outline-secondary",
                  hideHeaderClose: false,
                  centered: true,
                })
                .then((value) => {
                  if (value) {
                    this.showBulk()
                  }
                })
            } else {
              this.isButtonLoading = false
              this.$toastSuccess("File Upload", response.data.message)

              this.getInvitedCompanies()
              // this.$refs.uploadCSV.value = "";
              this.file_upload = ""
            }
          })
          .catch((error) => {
            this.isButtonLoading = false
            this.$toastDanger(
              "File Upload",
              "File upload failed. Try again later."
            )

            // this.$refs.uploadCSV.value = "";
            this.file_upload = ""
          })
      }
    },
    uploadJobs(input) {
      this.file_upload = input
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "File Attachment",
            icon: "AlertCircleIcon",
            text: "File has been attached successfully.",
            variant: "warning",
          },
        },
        { timeout: this.$longestTimeout }
      )
    },

    dateChanged(val, newVal, instance) {
      instance.close()
    },
    updateSelectedSlot(slot) {
      const all_buttons = document.getElementsByClassName("b-button-border")
      all_buttons.forEach((el) => {
        el.classList.remove("isActive")
      })

      this.interview_time = slot
    },
    humanFriendlyDate(date_string) {
      return format(new Date(date_string), "E, do LLL, yyyy")
    },
    viewApplicationStatus(tr, index) {
      this.current_table_index = index
      this.application_status_popup = true
      this.selected_application_status = tr.status.application_status
      this.application_note = tr.status.note
      this.booking_id = tr.booking_id
    },

    companyPage() {
      const company = this.$store.state.auth.ActiveUser.company_name

      const route = this.$router.resolve({ path: `/listings/${company}` })

      window.open(route.href, "_blank")
    },
    changeDescription(details) {
      this.description_manager = details
    },
    activatePreview(event_item) {
      // if (!this.event_section_type) {
      //   this.preview_popup = true;
      //   this.specified_event = event_item;
      // }

      if (event_item.event_category !== "group") {
        this.job = event_item
        this.single_preview_popup = true
      } else {
        this.event_id = event_item._id
        // let route = this.$router.resolve({ path: "/events/" + event_id });
        //   this.group_preview_link = route.href
        this.group_preview_popup = true
        // window.open(route.href, "_blank");
        // this.preview_popup = true;
        // this.specified_event = event_item;
      }
    },
    openInsidePop(all_job_ids, image) {
      if (all_job_ids.length > 0) {
        this.inside_data = []

        for (const item of all_job_ids) {
          this.inside_data.push({
            _id: item,

            details: this.all_event_records[item],
          })
        }
        this.popup_image = image
        this.description_manager = this.inside_data[0].details
        this.inside_preview_popup = true
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: "Group events",
              icon: "AlertCircleIcon",
              text: "There are no jobs posted by this company. Please check later",
              variant: "warning",
            },
          },
          { timeout: this.$longestTimeout }
        )
      }
    },

    onCopy(event_id, item) {
      if (item.event_category === "join") {
        this.share_link = `${window.location.origin}/listing/${item.selected_event_group}`
      } else if (item.event_category === "group") {
        this.share_link = `${window.location.origin}/listings/${event_id}_g`
      } else {
        this.share_link = `${window.location.origin}/listing/${event_id}`
      }

      this.specified_event = item
      // this.copyTextToClipboard(gen_link);
      this.social_popup = true
      // this.$vs.notify({
      //   time: 10000,
      //   title: "Success",
      //   text: "Event link copied successfully",
      //   color: "success",
      //   iconPack: "feather",
      //   position: "top-right",
      //   icon: "icon-check-circle",
      // });
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => { },
        (err) => { }
      )
    },

    handleInput: debounce(function (text) {
      this.event_employers = []
      this.getEmployers(text)
    }, 1000),
    handleJobsInput: debounce(function (text) {
      this.event_employers = []
      this.getEmployers(text)
    }, 1000),
    restoreCreds() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },
    getEmployers(query) {
      this.restoreCreds()

      this.$http
        .get(`/api/company-search/${query}`)
        .then((response) => {
          this.event_employers = []
          if (response.data.success) {
            for (const item of response.data.data) {
              this.event_employers.push({
                text: item.text,
                value: item.value,
              })
            }
          }
        })
        .catch((error) => { })
    },
    humanizedInterviewType(category) {
      if (category === "speed_interview") {
        return "Virtual Speed Interview"
      }
      if (category === "virtual") {
        return "Virtual Interview"
      }
      if (category === "physical") {
        return "In-Person Interview"
      }
      return "NA"
    },
    downloadResume(email) {
      const company = this.$store.state.auth.ActiveUser.company_name

      this.$http
        .get(`/api/resume-file/${this.applicant_info.resume_file}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          if (response.data.errors) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Download Notification",
                  icon: "AlertCircleIcon",
                  text: "Applicant hasn't uploaded their resume yet.",
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            )
          } else {
            const header = response.headers
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: header["content-type"] })
            )
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", this.applicant_info.resume_file) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((error) => { })
    },
    applist(applicants, event_details) {
      if (!this.restrict_access) {
        if (applicants.length > 0) {
          this.applications = applicants
          this.popupList = true
          this.current_applicant_event = event_details

          this.inside_event_type = event_details.event_type
          this.inside_event_category = event_details.event_category
        }
      }
    },
    viewApplicantInfo(item, index) {
      this.current_table_index = index
      this.applicant_info = item
      // const file_url = "http://localhost:81/api/resume-file/"+ item.resume_file
      const file_url = `${this.$http.defaults.baseURL}/api/resume-file/${item.resume_file}`
      if (item.resume_file && item.resume_file.endsWith("pdf")) {
        this.document_type = "pdf"
        this.file_source = file_url
      } else {
        this.document_type = "docs"
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${file_url}`
      }

      // this.popupList = false;
      this.popupApplicant = true
      const all_data = { viewed: "Yes", booking_id: item.booking_id }
      if (item.viewed === "No") {
        this.$http
          .put("/api/book", { data: all_data })
          .then((response) => {
            const { success } = response.data
            if (success) {
              this.$refs.viewed_profile[this.current_table_index].innerText =
                "Yes"
            }
          })
          .catch((error) => { })
      }
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        )} ......`
      }
      return string
    },

    add_record() {
      this.popupActive = !this.popupActive
      this.action = "insert"
      this.pickerLimits = {}
    },
    editEvent() {
      const item = this.specific_event_details

      const { employment_type } = item

      if (employment_type.includes("internship_traineeship")) {
        this.employment_type.internship_traineeship = true
      }
      if (employment_type.includes("freelance")) {
        this.employment_type.freelance = true
      }
      if (employment_type.includes("contract")) {
        this.employment_type.contract = true
      }
      if (employment_type.includes("permanent")) {
        this.employment_type.permanent = true
      }
      if (employment_type.includes("temporary")) {
        this.employment_type.temporary = true
      }

      if (employment_type.includes("flexiwork")) {
        this.employment_type.flexiwork = true
      }

      const selected_tags = item.tags

      this.action = "update"

      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      this.start_date = item.start_date
      this.end_date = item.end_date

      this.start_time = parse(item.start_time, "HH:mm:ss", new Date())
      this.end_time = parse(item.end_time, "HH:mm:ss", new Date())
      this.select_tags = selected_tags
      this.job_source = item.from_source
      this.job_type = item.job_type
      this.requirements = item.requirements
      this.type_candidate = item.type_candidate
      this.work_days = item.work_days
      this.work_hours = item.work_hours
      this.salary = item.salary
      this.aging_period = item.aging_period
      this.min_salary = item.min_salary
      this.salary_cycle = item.salary_cycle
      this.work_location = item.work_location
      this.remote = item.remote
      this.event_type = item.event_type
      this.custom_questions = item.questions
      this.event_status = item.event_status
      this.max_applications = item.max_applications
      this.all_companies_invited = item.all_companies_invited
      this.ems_section_type = item.event_category
      this.selected_hiring_managers = item.hiring_managers
      this.interview_duration = item.interview_duration
      this.interview_location = item.interview_location
      this.selected_company = { text: item.company, value: "" }
      this.event_visibility = item.event_visibility
      this.redirection_link = item.redirection_link
      this.featured = item.featured
      this.selected_event_group = item.selected_event_group
      this.image_url = item.image_url ? item.image_url : "default"
      if (this.start_date.length > 4 || this.end_date.length > 4) {
        this.start_end_date = {
          start: parse(this.start_date, "yyyy-MM-dd", new Date()),
          end: parse(this.end_date, "yyyy-MM-dd", new Date()),
        }
      }
    },
    edit_event() {
      const result = this.checkFilledFields()
      const join_event_id = this.$route.params.event_id

      if (result) {
        const all_data = this.extract_values()
        all_data.data_id =
          join_event_id && join_event_id.length > 2
            ? join_event_id
            : this.item_id
        all_data.type = this.action

        this.$http
          .put("/api/events", { data: all_data })
          .then((response) => {
            if (this.action === "update") {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text:
                      join_event_id && join_event_id.length > 2
                        ? "Your job has been updated successfully"
                        : "Your event has been updated successfully",
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Employment Data Deletion",
                    icon: "AlertCircleIcon",
                    text: "Data deleted successfully!",
                    variant: "danger",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }
            if (this.ems_section_type !== "group") {
              if (join_event_id && join_event_id.endsWith("_pending")) {
                window.location.href = `/events-manager/pending-jobs/${this.selected_event_group}`
              } else {
                this.$router.push("/events-manager/events")
              }
            } else {
              // this.dataRows = response.data.platform_jobs;
              // this.all_selected_events = response.data.all_selected_events;

              this.dataRows = response.data.all_selected_events

              this.all_selected_events = response.data.all_selected_events

              this.participating_events_data = response.data.platform_jobs

              if (this.all_selected_companies.length > 0) {
                // this.participating_events_data =
                //   this.dataRows.filter((job) =>
                //     this.all_selected_events.includes(job._id)
                //   );
              }

              // this.$refs.wizard.changeTab(0, 1);
              this.$refs.wizard.nextTab()
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text: "You can select the jobs that will be added to this event.",
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }
          })
          .catch((error) => { })
      }
    },
    deleteEvent(item) {
      this.current_item = item
      this.confirmDeleteRecord(item)
    },

    delete_event() {
      const all_data = this.current_item
      all_data.data_id = this.item_id
      all_data.type = "delete"

      this.$http
        .put("/api/events", { data: all_data })
        .then((response) => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: "Event Manager",
                icon: "AlertCircleIcon",
                text: "Your event has been archived successfully",
                variant: "success",
              },
            },
            { timeout: this.$longestTimeout }
          )
          this.getEvents()
        })
        .catch((error) => { })
    },
    confirmDeleteRecord(item) {
      this.$bvModal
        .msgBoxConfirm(
          `You are about to cancel the following event : \n ${item.position}`,
          {
            title: "Confirm Cancellation",
            size: "sm",
            okVariant: "danger",
            okTitle: "Upload New File",
            cancelTitle: "Cancel Event",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.showBulk()
          }
        })
    },
    sendInvite(details) {
      const all_data = { ...details, _id: this.item_id }
      this.$http
        .post("/api/send-invite", { data: all_data })
        .then((response) => {
          const { success } = response.data
          const { message } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Group Invite",
                  icon: "AlertCircleIcon",
                  text: message,
                  variant: "success",
                },
              },
              { timeout: this.$longestTimeout }
            )
            const objIndex = this.all_companies_invited.findIndex(
              (obj) => obj.uen_no == details.uen_no
            )
            this.all_companies_invited[objIndex].invite_sent = true
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Group Invite",
                  icon: "AlertCircleIcon",
                  text: message,
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            )
          }
        })
        .catch((error) => { })
    },
    getEvents() {
      this.isLoading = false
      this.restoreCreds()
      const { email } = this.$store.state.auth.ActiveUser
      this.current_user = email
      this.events_data = []
      let expected_type = "single"
      if (!this.event_section_type) {
        expected_type = "group"
      }
      this.event_groups = []
      const all_event_group = {}
      let show_archive = false
      show_archive = !this.archive_live_type
      this.$http
        .get("/api/events")
        .then((response) => {
          this.event_groups = orderBy(
            response.data.event_groups,
            ["text"],
            ["asc"]
          )

          if (response.data.success) {
            this.registered = response.data.registered
            this.all_companies_hiring_managers =
              response.data.hiring_manager_emails
            const hiring_managers = []
            const firstItem = Object.keys(this.all_companies_hiring_managers)[0]

            this.all_companies_hiring_managers[firstItem].map((user) => {
              hiring_managers.push({ text: user, value: user })
            })

            this.hiring_managers = hiring_managers

            for (const item of response.data.message) {
              this.all_event_records[item._id] = item
              if (item.event_category === "group") {
                all_event_group[item._id] = item
              }

              if (expected_type === item.event_category) {
                if (show_archive) {
                  if (item.archive) {
                    this.events_data.push(item)
                  }
                } else if (!item.archive) {
                  this.events_data.push(item)
                }

                this.event_exist = true
              } else if (
                item.event_category === "join" &&
                expected_type === "single"
              ) {
                item.cleaned_start_date = item.cleaned_start_date
                item.cleaned_end_date = item.cleaned_end_date
                item.start_time = item.start_time
                item.end_time = item.end_time
                item.image_url = item.company_image
                if (show_archive) {
                  if (item.archive) {
                    this.events_data.push(item)
                  }
                } else if (!item.archive) {
                  this.events_data.push(item)
                }

                this.event_exist = true
              } else if (
                item.event_category === "group" &&
                expected_type === "single"
              ) {
                this.events_data.push(item)
                this.event_exist = true
              }
            }
          } else {
            this.event_exist = false
          }
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    getPreferences() {
      const employment_type = []
      if (this.employment_type.internship_traineeship) {
        employment_type.push("internship_traineeship")
      }
      if (this.employment_type.freelance) {
        employment_type.push("freelance")
      }
      if (this.employment_type.temporary) {
        employment_type.push("temporary")
      }
      if (this.employment_type.contract) {
        employment_type.push("contract")
      }
      if (this.employment_type.permanent) {
        employment_type.push("permanent")
      }
      if (this.employment_type.flexiwork) {
        employment_type.push("flexiwork")
      }

      return employment_type
    },

    extract_values(edit_type) {
      const selected_tags = this.select_tags

      const employment_type = this.getPreferences()

      if (this.start_time === "Invalid Date") {
      }
      const company_name =
        typeof this.selected_company === "string"
          ? this.selected_company
          : this.selected_company.text

      const all_data = {
        position: this.position,
        description: this.description,
        start_date: this.start_date,
        end_date: this.end_date,
        start_time: this.start_time ? this.timeError(this.start_time) : "",
        end_time: this.end_time ? this.timeError(this.end_time) : "",
        tags: selected_tags,
        image_url: this.image_url,
        job_type: this.job_type,
        employment_type,
        requirements: this.requirements,
        type_candidate: this.type_candidate,
        work_days: this.work_days,
        work_hours: this.work_hours,
        salary: this.salary,
        min_salary: this.min_salary,
        salary_cycle: this.salary_cycle,
        work_location: this.work_location,
        remote: this.remote,
        aging_period: this.aging_period,
        all_companies_invited: this.all_companies_invited,
        hiring_managers: this.selected_hiring_managers,
        interview_duration: this.interview_duration,
        interview_location: this.interview_location,
        max_applications: this.max_applications,
        event_visibility: this.event_visibility,
        redirection_link: this.cleanRedirectionLink(),
        featured: this.featured,
        questions: this.custom_questions,
        event_status: this.event_status,
        event_type: this.event_type,
        company: company_name,
        event_category: this.ems_section_type,
        selected_event_group: this.selected_event_group,
        show: true,
        archive: false,
      }

      if (edit_type === "insert") {
        all_data.show = true
        all_data.archive = false
      }

      return all_data
    },
    showBulk() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: "File Upload",
            icon: "AlertCircleIcon",
            text: "Click bulk upload employers to upload a new file.",
            variant: "warning",
          },
        },
        { timeout: this.$longestTimeout }
      )
      this.file_upload = ""
    },

    emsUpload() {
      const input = this.file_upload
      const { email } = this.$store.state.auth.ActiveUser

      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => { }
        const fd = new FormData()
        fd.append("event_visibility", this.event_visibility)
        fd.append("event_category", this.ems_section_type)
        fd.append("event_group", this.selected_event_group)

        fd.append("start_date", this.start_date)
        fd.append("end_date", this.end_date)
        fd.append("start_time", this.start_time)
        fd.append("end_time", this.end_time)

        fd.append("file", input.target.files[0])
        delete this.$http.defaults.headers.common.Authorization
        this.$http
          .post(`/api/upload-ems/${email}`, fd)
          .then((response) => {
            if (response.data.errors.length > 0) {
              this.popupActive = false
              this.$bvModal
                .msgBoxConfirm(response.data.errors.join("\n"), {
                  title: "Errors detected",
                  size: "sm",
                  okVariant: "danger",
                  okTitle: "Upload New File",
                  cancelTitle: "Cancel",
                  cancelVariant: "outline-secondary",
                  hideHeaderClose: false,
                  centered: true,
                })
                .then((value) => {
                  if (value) {
                    this.showBulk()
                  }
                })
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "File Upload",
                    icon: "AlertCircleIcon",
                    text: response.data.message,
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              ) // this.$refs.uploadCSV.value = "";
              this.file_upload = ""
              this.getEvents()
              if (this.$route.params.event_id) {
                this.$router.push("/events-manager/events")
              }
            }
          })
          .catch((error) => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "File Upload",
                  icon: "AlertCircleIcon",
                  text: "File upload failed. Try again later.",
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            ) // this.$refs.uploadCSV.value = "";
            this.file_upload = ""
          })
        this.ems_file_popup = false
        this.popupActive = false
      }
    },

    checkFilledFields() {
      let proceed = true
      let message = ""

      if (
        this.ems_section_type === "single" ||
        this.ems_section_type === "join"
      ) {
        if (this.position.length < 1) {
          message += "A valid job position title is required\n"
        }
        if (this.description.length < 1) {
          message += "A valid job description is required\n"
        }

        if (
          this.requirements.length < 1 &&
          !["mcf", "efc"].includes(this.job_source)
        ) {
          message += "A valid job requirement is required\n"
        }
        if (this.work_days.length < 1) {
          message += "A valid working days is required\n"
        }
        if (this.work_hours.length < 1) {
          message += "A valid working time is required\n"
        }
        if (parseInt(this.min_salary) < 1) {
          message += "A valid minimum salary is required\n"
        }

        if (parseInt(this.min_salary) > parseInt(this.salary)) {
          message +=
            "The minimum salary cannot be greater than the maximum salary\n"
        }

        if (this.salary_cycle.length < 1) {
          message += "You need to select at least one salary cycle\n"
        }

        if (this.work_location.address.length < 1) {
          message += "A valid address is required\n"
        }

        if (this.work_location.state.length < 1) {
          message += "A valid state is required\n"
        }

        if (this.work_location.country.length < 1) {
          message += "You need to select a country\n"
        }

        if (this.work_location.postal_code.length < 1) {
          message += "A valid postal code is required\n"
        }

        if (parseInt(this.salary) < 1) {
          message += "A valid maximum salary is required\n"
        }

        if (this.event_type.length < 1) {
          message += "You need to specify the interview type\n"
        }

        if (this.job_type.length < 3) {
          message += "You need to choose at least one job type\n"
        }

        if (this.getPreferences().length < 1) {
          message += "You need to select at least one employment type\n"
        }

        if (this.ems_section_type == "single") {
          if (this.start_date.length < 1) {
            message += "A valid start date is required\n"
          }
          if (this.end_date.length < 1) {
            message += "A valid end date is required\n"
          }
          if (this.start_time.length < 1) {
            message += "A valid start time is required\n"
          }
          if (this.end_time.length < 1) {
            message += "A valid end time is required\n"
          }
        }

        if (this.ems_section_type === "join") {
          if (this.selected_event_group.length < 3) {
            message += "Choose your event group\n"
          }
        }
      } else if (this.ems_section_type === "group") {
        if (this.position.length < 1) {
          message += "A valid event group name is required\n"
        }
        if (this.event_visibility.length < 1) {
          message += "You need to select the event type\n"
        }
        if (this.start_date.length < 1) {
          message += "A valid event start date is required\n"
        }
        if (this.end_date.length < 1) {
          message += "A valid event end date is required\n"
        }
        if (this.start_time.length < 1) {
          message += "A valid event start time is required\n"
        }
        if (this.end_time.length < 1) {
          message += "A valid event end time is required\n"
        }
      }

      if (message.length > 2) {
        proceed = false
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: "Missing Event Data",
              icon: "AlertCircleIcon",
              text: message,
              variant: "danger",
              bodyClass: "text-break",
            },
          },
          { timeout: this.$longestTimeout }
        )
      }

      return proceed
    },
    addEvent() {
      const result = this.checkFilledFields()
      if (result) {
        const all_data = this.extract_values()

        this.$http
          .post("/api/event", { data: all_data })
          .then((response) => {
            const { success } = response.data

            this.dataRows = response.data.platform_jobs
            this.selected_event_group = response.data.data

            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text: response.data.message,
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text: response.data.message,
                    variant: "danger",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }

            if (this.ems_section_type !== "group") {
              if (
                Object.keys(this.$store.state.auth.specificEvent).length > 0
              ) {
                this.ems_section_type = "group"
                this.selected_event_group =
                  this.$store.state.auth.specificEvent._id || ""
                // this.$refs.wizard.changeTab(0, 1);
                this.$refs.wizard.nextTab()
              } else {
                this.$router.push("/events-manager/events")
              }
            } else {
              // this.$refs.wizard.changeTab(0, 1);
              this.$router.push(
                `/events-manager/edit-event/${this.selected_event_group}-N`
              )
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: "Event Manager",
                    icon: "AlertCircleIcon",
                    text: "You can select the jobs that will be added to this event.",
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              )
            }

            if (this.ems_section_type === "group") {
              this.$store
                .dispatch("auth/updateEventInfo", response.data.event_details)
                .catch((err) => { })

              this.$store
                .dispatch(
                  "auth/updatePlatformJobs",
                  response.data.platform_jobs
                )
                .catch((err) => { })
            }

            this.getEvents()
            // if (this.$route.params.event_id) {
            //   this.$router.push("/events-manager/events");
            // }
          })
          .catch((error) => { })

        this.popupActive = false
      }
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        const outer_this = this

        const fd = new FormData()

        const img = new Image()
        img.src = window.URL.createObjectURL(input.target.files[0])
        img.onload = () => {
          if (img.height < 540 || img.width < 1200) {
            outer_this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Image size error",
                  icon: "AlertCircleIcon",
                  text: "The image you have uploaded is too small. The minimum accepted width and height is 1200px and 540px respectively.",
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            )
            return false
          }

          if (img.width < img.height) {
            outer_this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: "Image size error",
                  icon: "AlertCircleIcon",
                  text: "Please make sure the image width is larger than its height.",
                  variant: "danger",
                },
              },
              { timeout: this.$longestTimeout }
            )
            return false
          }

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: "Upload Status",
                icon: "AlertCircleIcon",
                text: "Uploading event image in progress.",
                variant: "warning",
                progress: 0,
                progressId: "uploadEventImage",
              },
            },
            { timeout: this.$fileUploadTimeout }
          )

          fd.append("file", input.target.files[0])
          // delete this.$http.defaults.headers.common["Authorization"];
          outer_this.isLoading = true
          // outer_this.$vs.loading({
          //   background: 'primary',
          //   color: '#fff',
          //   container: '#button-with-loading',
          //   scale: 0.45,
          // })

          this.$http
            .post("/api/event-image", fd, {
              onUploadProgress(progressEvent) {
                const uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                )
                const parent = document.querySelector("#uploadEventImage")
                parent.querySelector(
                  ".progress-bar"
                ).style = `width: ${uploadPercentage}%;`
              },
            })
            .then((response) => {
              if (response.data.success) {
                outer_this.image_url = response.data.image
                outer_this.isLoading = false
              } else {
                outer_this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: "Picture Upload Status",
                      icon: "AlertCircleIcon",
                      text: response.data.message,
                      variant: "danger",
                    },
                  },
                  { timeout: this.$longestTimeout }
                )
              }
              outer_this.isLoading = false
            })
            .catch((error) => { })

          const { token } = outer_this.$store.state.auth.ActiveUser
          this.$http.defaults.headers.common.Authorization = `Token ${token}`
          reader.readAsDataURL(input.target.files[0])
          return true
        }
      }
    },
    onClickCloseButton() {
      this.$emit("closePopup")
    },

    showDateRangeFilter(show, field) {
      this.dateRangeFilter = show
      this.dateRangeField = field
    },
    resetDateRangeFilter(show, field) {
      this.dateRangeField = field
      delete this.serverParams.columnFilters[field]
      this.startEndDate = null
      this.updateParams({})
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
.text-body {
  white-space: pre;
}

.card-img-top {
  width: 50%;
  height: 300px;
}

.date-hidden .vc-date {
  display: none !important;
}

.vs__selected {
  color: black;
}
</style>
