<!-- eslint-disable camelcase -->
<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="formData" class="vx-row w-100 my-8 ml-1">
        <b-card border-variant="dark" bg-variant="transparent" class="shadow-none container w-75">
          <div id="button-with-loading" class="d-flex justify-content-center"
            :class="formData.banner_image_url ? '' : 'border border-danger'">
            <img :src="formData.banner_image_url
                ? formData.banner_image_url
                : require('@/assets/images/placeholder/profile.svg')
              " alt="content-img" class="responsive card-img-top mx-auto" />
          </div>
          <b-alert show variant="warning" class="my-1 p-1" icon-pack="feather" icon="icon-info">
            The minimum accepted width and height of the image is 1200px and
            540px respectively.
          </b-alert>
          <file-pond ref="pond-event-image" name="event-image-upload" label-idle="Upload a new event banner image."
            accepted-file-types="image/jpeg, image/png" :server="eventImageServer" :files="eventImageFiles"
            credits="false" @init="handleEventImagePondInit" />
        </b-card>

        <h6 class="mt-1 font-weight-bolder">
          If you want applicants to be redirected after they click the event
          image, add the link below
        </h6>
        <b-form-input v-model="formData.redirection_link" class="w-full" placeholder="" />
        <div>
          <h6 class="mt-1 font-weight-bolder" :class="formData.name ? '' : 'text-danger'">
            Event Group Name *
          </h6>
          <b-form-input v-model="formData.name" required class="w-full"
            :danger="formData.name.length > 0 ? false : true" danger-text="A valid event group name is required."
            val-icon-danger="icon-x" val-icon-pack="feather" />
        </div>

        <div class="mt-2">
          <div class="w-full">
            <h6 v-b-tooltip.hover.top="'This date specifies the days when the group event will begin and end.'
              " class="mt-1 font-weight-bolder" :class="formData.start_end_date ? '' : 'text-danger'">
              Event Start Date And End Date *
            </h6>
            <v-date-picker v-model="formData.start_end_date" :class="formData.start_end_date ? '' : 'danger-select'"
              mode="date" :masks="masks" color="orange" :columns="$isMobile() ? 1 : 2" is-range is-expanded />
          </div>
        </div>

        <b-row class="mt-1">
          <b-col cols="6">
            <h6 class="mt-2 mb-1 font-weight-bolder" :class="formValidation.start_time.state ? '' : 'text-danger'">
              Event Start Time *
            </h6>
            <v-date-picker v-model="formData.start_time" :class="formValidation.start_time.state ? '' : 'danger-select'"
              mode="time" color="orange" is-expanded class="date-hidden w-full" @input="validateForm" />
            <b-form-invalid-feedback :state="formValidation.start_time.state">
              {{ formValidation.start_time.message }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col cols="6">
            <h6 class="mt-2 mb-1 font-weight-bolder" :class="formValidation.end_time.state ? '' : 'text-danger'">
              Event End Time *
            </h6>
            <v-date-picker v-model="formData.end_time" :class="formValidation.end_time.state ? '' : 'danger-select'"
              mode="time" color="orange" is-expanded class="date-hidden w-full" @input="validateForm" />
            <b-form-invalid-feedback :state="formValidation.end_time.state">
              {{ formValidation.end_time.message }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <h6 class="mt-1 font-weight-bolder" :class="formData.visibility_type ? '' : 'text-danger'">
          Event Type *
        </h6>
        <b-row>
          <b-col cols="6">
            <b-form-radio v-model="formData.visibility_type" class="mx-2" value="private">Private</b-form-radio>
          </b-col>

          <b-col cols="6">
            <b-form-radio v-model="formData.visibility_type" class="mx-2" value="public">Public</b-form-radio>
          </b-col>
        </b-row>

        <h6 class="mt-1 font-weight-bolder">Event Interview Format *</h6>
        <div class="d-flex flex-wrap justify-content-between mb-1">
          <div class="vx-col">
            <b-form-radio v-model="formData.event_type" class="mx-2" value="physical">In-Person Interview</b-form-radio>
          </div>

          <div class="vx-col">
            <b-form-radio v-model="formData.event_type" class="mx-2" value="virtual">Virtual Interview</b-form-radio>
          </div>

          <div class="vx-col">
            <b-form-radio v-model="formData.event_type" class="mx-2" value="speed_interview">Virtual Speed
              Interview</b-form-radio>
          </div>

          <div class="vx-col w-1/2">
            <b-form-radio v-model="formData.event_type" class="mx-2" :value="null">None</b-form-radio>
          </div>
        </div>

        <div v-if="formData.event_type === 'speed_interview'" class="vx-row mb-1">
          <b-form-input v-model="formData.interview_duration" type="number" class=""
            placeholder="How many minutes should the interview last?" />
        </div>

        <div v-if="formData.event_type === 'physical'" class="vx-row mt-2 mb-1">
          <b-form-input v-model="formData.interview_location" type="text" class="w-full"
            placeholder="What will be the location of the event?" />

          <!-- <gmaps-map>
                <gmaps-marker :options="interview_location" />
              </gmaps-map> -->
        </div>

        <div v-if="formData.event_type === 'physical'" class="vx-row mt-2 mb-1">
          <b-form-input v-model="formData.maximum_participants" type="number" class="w-full"
            :class="formData.maximum_participants ? '' : 'border-danger'"
            placeholder="What will be the maximum number of participants for this event?" />

          <!-- <gmaps-map>
                <gmaps-marker :options="interview_location" />
              </gmaps-map> -->
        </div>
        <h6 class="mt-1 font-weight-bolder">Publication Status *</h6>
        <b-row>
          <b-col>
            <b-form-radio v-model="formData.publication_status" class="mx-2" value="draft">Draft</b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio v-model="formData.publication_status" class="mx-2" value="published">Published</b-form-radio>
          </b-col>
          <!-- Sugianto 20230415 there was no pending status on publicaton_status on Event so will comment it out -->
          <!-- <b-col>
            <b-form-radio
              v-model="formData.publication_status"
              class="mx-2"
              value="pending"
            >Pending</b-form-radio>
          </b-col> -->
        </b-row>

        <h6 class="mt-1 font-weight-bolder">
          Customised Screening Questions (After typing each question press
          enter)
        </h6>
        <b-form-tags v-model="formData.questions" input-id="tags-basic" class="w-full"
          placeholder="Click enter after adding each question" />
        <h6 class="mt-1 font-weight-bolder">
          How many days will employers be allowed to view the applicants details
          after the event ends (After this period they wont be able to view) *
        </h6>

        <b-form-input v-model="formData.aging_period" class="w-full mt-8" type="number"
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          placeholder="If the number of days equal to 0, the employers will be allowed to view the applicants details for an unlimited period of time" />
        <h6 class="mt-1 font-weight-bolder">Event Industry</h6>
        <v-select v-model="formData.industry" placeholder="Choose Your Event Industry" multiple
          class="text-white selectExample w-full" :options="all_industries" label="value"
          :reduce="(value) => value.value" />
      </div>
      <div class="vx-row my-3">
        <div class="vx-col mx-auto float-right">
          <ButtonSpinner :is-loading="isLoading">
            <b-button v-if="operation === 'write'" variant="primary" class="mr-3 w-64" @click="addEvent">
              {{ $route.name == "events-data" ? "Submit" : "Next" }}
            </b-button>
            <b-button v-else variant="primary" class="mr-3 w-64" @click="editEvent">
              Next
            </b-button>
          </ButtonSpinner>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond"
// Import FilePond styles
import "filepond/dist/filepond.min.css"
// Import FilePond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import { orderBy } from "lodash"
import { allIndustries } from "@/constants/datastore"
import vSelect from "vue-select"
import { format } from "date-fns"
import { singleUpload } from "@/components/awsS3"
import axios from "@axios"

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  name: "EventForm",
  components: {
    FilePond,
    vSelect,
  },
  props: {
    wizardRef: Object,
    // * Part of removing loading screen bug
    eventIdProp: String,
  },
  data() {
    return {
      eventImageServer: {},
      eventImageFiles: null,
      formValidation: {
        start_time: {},
        end_time: {},
      },
      formData: {
        banner_image: null,
        banner_image_url: null,
        redirection_link: "",
        name: "",
        start_end_date: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        visibility_type: "public",
        event_type: "None",
        interview_duration: null,
        interview_location: "",
        maximum_participants: null,
        publication_status: "draft",
        questions: [],
        aging_period: 0,
        industry: [],
      },

      oldFormData: {},
      temporaryFileName: null,
      external_platform_jobs: [],
      isLoading: false,
      masks: {
        input: "YYYY-MM-DD",
      },
      operation: "write",
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(data) {
        if (data.start_end_date) {
          try {
            if (data.start_end_date.start) {
              this.formData.start_date = format(
                data.start_end_date.start,
                "yyyy-MM-dd"
              )
              this.formData.end_date = format(
                data.start_end_date.end,
                "yyyy-MM-dd"
              )
            }

            // eslint-disable-next-line no-empty
          } catch (_) { }
        }
      },

      //   this.start_date = format(val.start, 'yyyy-MM-dd')
      //   this.end_date = format(val.end, 'yyyy-MM-dd')
    },
  },
  created() {
    this.all_industries = orderBy(allIndustries, ["value"], ["asc"])
  },
  mounted() {

    this.initComponent()
  },
  methods: {
    getEventByID(eventID) {
      this.isLoading = true
      this.$http
        .get(`/api/event/${eventID}`)
        .then((response) => {
          this.operation = "update"
          this.isLoading = false
          const eventDetails = response.data.output
          this.oldFormData = JSON.parse(JSON.stringify(eventDetails))
          this.formData = eventDetails

          this.validateForm()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    initComponent() {
      // * Part of removing loading screen bug
      // let eventID = this.$route.params.event_id
      let eventID = this.eventIdProp

      if (eventID) {
        if (eventID.endsWith("-N")) {
          eventID = eventID.replace("-N", "")
          this.event_id = eventID
          this.getEventByID(eventID)
          if (this.wizardRef) {
            this.wizardRef.nextTab()
          }
        } else if (eventID.endsWith("_job")) {
          // do nothing
        } else if (eventID.endsWith("_pending")) {
          // do nothing
        } else {
          this.event_id = eventID
          this.getEventByID(eventID)
        }
      }
    },
    handleEventImagePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this

      this.$refs["pond-event-image"]._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split(".").pop()
            const uuid = crypto.randomUUID()

            outerThis.temporaryFileName = `${uuid}.${extension}`

            singleUpload(
              file,
              "event-images",
              outerThis.temporaryFileName,
              load,
              error,
              progress,
              abort,
              null,
              outerThis
            )
            // outerThis.formData.banner_image = key
          },
        },
      })
    },

    checkFilledFields() {
      let proceed = true
      const unfilledCols = this.$el.getElementsByClassName("text-danger")
      if (unfilledCols.length > 0) {
        proceed = false
        const element = unfilledCols[0]
        element.scrollIntoView()
        this.$toastDanger(
          "Missing / Invalid Event Data",
          "Please fill in the required and valid values for the highlighted fields."
        )
      }

      return proceed
    },
    addEvent() {
      const result = this.checkFilledFields()

      if (result) {
        this.isLoading = true

        this.cleanRedirectionLink()
        this.$http
          .post("/api/event", this.formData)
          .then((response) => {
            const { success, event_data } = response.data
            if (success) {
              // const extension = this.temporaryFileName.split('.').pop()
              // renameObjectByKey(this.temporaryFileName, `${event_data.id}.${extension}`)
              this.isLoading = false
              this.$toastSuccess("Event Manager", "Event created successfully")
            } else {
              this.isLoading = false
              this.$toastDanger("Event Manager", response.data.message)
            }

            // this.$refs.wizard.changeTab(0, 1);
            // this.$router.push(
            //   // `/events-manager/edit-event/${event_data.id}-N`,
            //   `/events-manager/edit-event/${event_data.id}`,
            // )


            if (this.$route.name === "events-data") {
              this.$emit("submitted", true)
            } else {
              // const a = document.createElement("a")
              // a.href = this.$router.resolve(
              //   `/events-manager/edit-event/${event_data.id}?created=true`
              // ).href
              // window.location.href = a.href

              // * Part of removing loading page bug fix
              // TODO: emit function to set event data id and event data name in the EditEvent.vue
              // TODO: pass these two data to JobForm and EventForm
              this.$emit("on-add-event-handler", event_data.id)

              // history.replaceState(
              //   {},
              //   "",
              //   `${this.$route.path}/events-manager/edit-event/${event_data.id}?created=true`
              // )
            }

            // this.$toastSuccess(
            //   "Event Manager",
            //   "You can select the jobs that will be added to this event."
            // )

            this.$store.dispatch("auth/updateEventInfo", event_data).catch()

            // this.$store
            //   .dispatch(
            //     'auth/updatePlatformJobs',
            //     response.data.platform_jobs,
            //   )
            //   .catch()

            // this.getEvents()
            // if (this.$route.params.event_id) {
            //   this.$router.push("/events-manager/events");
            // }
          })
          .catch((error) => {
            this.isLoading = false
          })
      }
    },
    editEvent() {
      const result = this.checkFilledFields()

      if (result) {
        const updatedFields = Object.fromEntries(
          Object.entries(this.formData).filter(
            ([key, value]) => this.oldFormData[key] !== value
          )
        )

        // * Part of removing loading screen bug
        // updatedFields.id = this.$route.params.event_id
        updatedFields.id = this.eventIdProp

        // pydantic converts the time to -8 hours because of the GMT
        const tzoffset =
          new Date(updatedFields.start_time).getTimezoneOffset() * 60000 // offset in milliseconds
        updatedFields.start_time = new Date(
          new Date(updatedFields.start_time) - tzoffset
        ).toISOString()
        updatedFields.end_time = new Date(
          new Date(updatedFields.end_time) - tzoffset
        ).toISOString()

        this.isLoading = true
        this.$http
          .put("/api/event", updatedFields)
          .then((response) => {
            if (response.data.success) {
              this.$toastSuccess(
                "Event Manager",
                "Your event has been updated successfully"
              )
              this.isLoading = false
            }
            // this.$router.push('/events-manager/events')
            // this.$refs.wizard.changeTab(0, 1);
            if (this.wizardRef) {
              // this.wizardRef.nextTab()
              this.wizardRef.changeTab(0, 1)
            }
            // this.$toast(
            //   {
            //     component: this.$toastContent,
            //     props: {
            //       title: 'Event Manager',
            //       icon: 'AlertCircleIcon',
            //       text: 'You can select the jobs that will be added to this event.',
            //       variant: 'success',
            //     },
            //   },
            //   { timeout: this.$longestTimeout },
            // )
          })
          .catch((error) => {
            this.isLoading = false
            console.error(error)
          })
      }
    },

    cleanRedirectionLink() {
      if (this.formData.redirection_link) {
        this.formData.redirection_link = this.formData.redirection_link
          .toLowerCase()
          .replace("https://", "")
          .replace("https://", "")
        if (this.formData.redirection_link.length > 0) {
          this.formData.redirection_link = `https://${this.formData.redirection_link}`
        } else {
          this.formData.redirection_link = null
        }
      } else {
        this.formData.redirection_link = null
      }

      return this.formData.redirection_link
    },

    validateForm() {
      this.validateStartTime()
      this.validateEndTime()
    },
    validateStartTime() {
      if (this.formData.end_time) {
        if (this.formData.start_time > this.formData.end_time) {
          this.formValidation.start_time.state = false
          this.formValidation.start_time.message =
            "Event Start Time cannot be later than Event End Time"
        } else {
          this.formValidation.start_time.state = true
          this.formValidation.start_time.message = null

          this.formValidation.end_time.state = true
          this.formValidation.end_time.message = null
        }
      }
    },
    validateEndTime() {
      if (this.formData.start_time) {
        if (
          this.formData.end_time < this.formData.start_time &&
          this.formData.end_time !== this.formData.start_time
        ) {
          this.formValidation.end_time.state = false
          this.formValidation.end_time.message =
            "Event End Time cannot be earlier than Event Start Time"
        } else {
          this.formValidation.start_time.state = true
          this.formValidation.start_time.message = null

          this.formValidation.end_time.state = true
          this.formValidation.end_time.message = null
        }
      }
    },
  },
}
</script>
